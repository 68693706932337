/**
* Created by hansxing on 2018/8/7.
*/
<style lang="scss" scoped>
    .bEnd-user-list {
        &.eTrip-section-cont{
            min-width: 980px;
        }

        .user-list-screen {
            background: #ffffff;
            padding: 20px;
        }
        .screen-company-business {
            margin-bottom: 10px;
        }
        .screen-company-business {
            .el-col-3 {
                width: 135px;
                margin-right: 10px;
            }

        }
        .screen-radio-change {
            margin-bottom: 10px;
            ul {
                li {
                    float: left;
                    margin-right: 10px;
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
        .screen-operate-btn {
            position: absolute;
            bottom: 0;
            right: 10px;
            .operate-btn-t {
                background: transparent;
                border: 0;
                padding: 5px;
                color: #409EFF;
            }
        }
        .user-list-table {
            background: #fff;
            margin-top: 10px;
            /*padding: 0 20px;*/
            .not-through-popover {
                display: inline-block;
                cursor: pointer;
                .el-popover__reference {
                    color: #FF3B30;
                }
            }
        }
        .screen-company-btn{
            position: relative;
            height: 40px;


        }
    }
</style>
<style lang="scss">
    .bEnd-user-list {
        .screen-radio-change {
            .el-radio-button:first-child {
                .el-radio-button__inner {
                    padding: 12px 25px;
                }
            }
            .screen-contract-effective {
                .el-radio-button__inner {
                    padding: 12px 25px;
                }
            }
            .el-radio-button__inner {
                padding: 12px 10px;
            }
        }


        .common-list-search {
            .list-search-before {
                width: calc(100% - 156px);
            }
            .list-search-after {
                width: 156px;
                text-align: right;
            }
        }
    }
</style>
<template>

    <div>
        <el-main class="com-eTrip-section">
            <div class="eTrip-section-cont bEnd-user-list">
                <com-list-search>
                    <div slot="list-search-before">
                        <el-form :inline="true" size="small">
                            <el-form-item label="">
                                <el-input v-model="bEndInfoInput.corporateName" placeholder="请输入公司名称"></el-input>
                            </el-form-item>
                            <el-form-item label="">
                                <el-input v-model="bEndInfoInput.userId" placeholder="用户ID"></el-input>
                            </el-form-item>
                            <el-form-item label="" style="width: 145px">
                                <el-input v-model="bEndInfoInput.belongedFirstName" placeholder="所属一级用户名称"></el-input>
                            </el-form-item>
                            <el-form-item label="" style="width: 214px">
                                <el-radio-group v-model="bEndUserTypeState.screenUserType">
                                    <el-radio-button :label="-1">全部</el-radio-button>
                                    <el-radio-button label="1___">一级用户
                                        <!--<span v-text="bEndCompanyTable.firstMerchantCount"></span>-->
                                    </el-radio-button>
                                    <el-radio-button label="_1__">二级用户
                                        <!--<span v-text="bEndCompanyTable.secondMerchantCount"></span>-->
                                    </el-radio-button>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label="" style="width: 166px">
                                <el-radio-group v-model="bEndUserTypeState.screenContract">
                                    <el-radio-button :label="-1">全部</el-radio-button>
                                    <el-radio-button :label="0">有效
                                        <!--<span v-text="bEndCompanyTable.contractWillExpireCount"></span>-->
                                    </el-radio-button>
                                    <el-radio-button :label="1">无效
                                        <!--<span v-text="bEndCompanyTable.contractExpiredCount"></span>-->
                                    </el-radio-button>
                                </el-radio-group>
                            </el-form-item>
                        </el-form>
                    </div>

                    <div slot="list-search-after" class="list-search-after_sec">
                        <el-button @click="bEndScreenResetClick()">重置</el-button>
                        <el-button type="primary" @click="bEndScreenBtnClick()">搜索</el-button>
                    </div>
                </com-list-search>


                <div class="user-list-table el-table_border_none">
                    <el-table class="table-info"
                              border
                              :data="bEndCompanyTable"
                              v-loading="bEndTableLoading"
                              style="width: 100%">
                        <el-table-column
                                fixed
                                prop="merchantId"
                                label="用户ID"
                                width="150">
                        </el-table-column>
                        <el-table-column
                                prop="companyName"
                                label="公司名称"
                                min-width="150px">
                        </el-table-column>
                        <el-table-column
                                label="用户类型"
                                min-width="80">
                            <template slot-scope="scope">
                                <p v-if="scope.row.merchantPid == 0">一级用户</p>
                                <p v-if="scope.row.merchantPid != 0">二级用户</p>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="firstCompanyName"
                                label="所属一级用户"
                                min-width="150">
                        </el-table-column>
                        <el-table-column
                                label="有效性"
                                width="220">
                            <template slot-scope="scope">
                                <p v-if="scope.row.isDeleted == 0">有效</p>
                                <p v-if="scope.row.isDeleted == 1">无效</p>
                            </template>
                        </el-table-column>
                        <!--<el-table-column-->
                        <!--label="账号数量"-->
                        <!--width="80">-->
                        <!--</el-table-column>-->

                        <!--<el-table-column-->
                        <!--fixed="right"-->
                        <!--label="操作"-->
                        <!--width="100">-->
                        <!--<template slot-scope="scope">-->
                        <!--<el-button @click="seeListInfoClick(scope.row.id)" type="text" size="small">查看</el-button>-->
                        <!--</template>-->
                        <!--</el-table-column>-->
                    </el-table>
                    <div class="table-page-number">
                        <div class="block">
                            <el-pagination
                                    @current-change="bEndTablePageNumberChange"
                                    :current-page.sync="bEndListPage.currentPage"
                                    :page-size="bEndListPage.pageSize"
                                    layout="total, prev, pager, next"
                                    :total="bEndListPage.totalPage">
                            </el-pagination>
                        </div>
                    </div>
                </div>


            </div>
        </el-main>

    </div>

</template>

<script type="text/ecmascript-6">
    import comListSearch from '../../../components/comListSearch/index.vue'

    export default {
        name: "bEndUserList",
        //定义模版数据
        data() {
            return {
                bEndUserTypeState: {  // 审核状态、用户类型、合同
                    screenUserType: -1,  // 用户类型搜索：全部1111，一级1000，二级0100，供应商0010，运营商0001
                    screenContract: -1,  // 是否有效 0 有效 1无效 ,
                },
                bEndInfoInput: {  // 输入
                    userId: '',  // 用户ID
                    corporateName: '',  // 公司名称
                    belongedFirstName: '',  // 所属一级用户名称
                },
                bEndCompanyTable: [
//                    {orgId: '3456789',  // 用户ID
//                    companyName: '上海久柏目的地旅游服务有限公司',  // 公司名称
//                    businessContractName: '杨宗纬',  // 业务联系人姓名 ,
//                    businessContractPhone: '13487221888',  // 业务联系人手机号
//                    firstMerchantValidate:'0',  // 一级用户  0无效 1有效
//                    secondMerchantValidate:'2',  // 二级用户  0无效 1有效
//                    supplierValidate:'0',  // 供应商  0无效 1有效
//                    operatorValidate:'1',  // 运营商  0无效 1有效
//                    approvalStatus:'1',  // 审核状态 1待提交，2待审核，3已通过，4未通过 ,
//                }
                ],
                bEndTableLoading: false,
                bEndListPage: {  // 列表分页
                    pageSize: 10,  // 每页显示条目个数
                    totalPage: 1,  // 总条目个数
                    currentPage: 1,  // 当前页数
                },
            }
        },
        components: {comListSearch},
        //计算属性
        computed: {},
        //主件被加载完成
        mounted: function () {
            this.bEndOrganizationList()

        },
        //定义事件方法
        methods: {

            async bEndOrganizationList() {  // 获取一级用户二级用户列表
                this.bEndTableLoading = true;
                let form = {
                    pageIndex: this.bEndListPage.currentPage,
                    pageSize: this.bEndListPage.pageSize
                };
                if (this.bEndInfoInput.corporateName) form.companyName = this.bEndInfoInput.corporateName;  // 公司名称
                if (this.bEndInfoInput.userId) form.merchantId = this.bEndInfoInput.userId;  // 用户ID
                if (this.bEndInfoInput.belongedFirstName) form.firstCompanyName = this.bEndInfoInput.belongedFirstName;  // 所属一级用户名称
                if (this.bEndUserTypeState.screenUserType != -1) form.userType = this.bEndUserTypeState.screenUserType;  // 用户类型
                if (this.bEndUserTypeState.screenContract != -1) form.isDeleted = this.bEndUserTypeState.screenContract;  // 有效无效

                try {
                    let res = await this.http('/galaxyOrgInfoApi/firstAndSecondOrgInfoPage', form, 'POST');
                    if (res.success) {
                        this.bEndTableLoading = false;
                        this.bEndCompanyTable = res.data.data;
                        this.bEndListPage.totalPage = res.data.total;  // 总条目个数
                    }
//                    else {
                    // this.messageError(res.errors[0].message);
//                        this.messageError('系统出错，请稍后!');
//                    }
                } catch (error) {
                    this.messageError()
                }
            },
            bEndScreenBtnClick() {  // 搜索
                this.bEndListPage.currentPage = 1;
                this.bEndOrganizationList()


            },
            bEndScreenResetClick() {  // 重置
                this.bEndInfoInput.corporateName = '';  // 公司名称
                this.bEndInfoInput.userId = '';  // 用户ID
                this.bEndInfoInput.belongedFirstName = '';  // 所属一级用户名称
                this.bEndUserTypeState.screenUserType = -1;  // 用户类型
                this.bEndUserTypeState.screenContract = -1;  // 合同是否过期
                this.bEndOrganizationList()
            },
            bEndTablePageNumberChange(val) {
                this.bEndListPage.currentPage = val;
                this.bEndOrganizationList()
            },
        },
        //监听模版变量
        watch: {}

    }
</script>

